#gridContainer {
    display: flex;
}

.grid {
    cursor: crosshair;
    border: 2px solid #999;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.highlight {
    border-color: #ffe283 !important;
}

.boardImageControls {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-right: 25px;
}

.rowNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    margin-right: 5px;
    color: #999999;
}

.boardTitle {
    cursor: default;
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.boardTitle > .label {
    display: inline;
    cursor: pointer;
}

.boardTitle > .hidden {
    position: absolute;
    left: -1000px;
}

.boardTitle > .input {
    font-size: 1.0em;
    text-align: center;
}
