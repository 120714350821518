.previewDialogContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.previewDialogContent > .controls > button:first-child {
    margin-right: 5px;
}

#image {
    border: 1px solid darkgray;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}
