#legend-p {
    position: absolute;
    left: -1000px;
    display: flex;
    flex-direction: column;
}

.entry-p {
    display: inline-flex;
    align-items: flex-end;
    margin-bottom: 3px;
}

.entryText-p {
    min-width: 10em;
    padding-left: 20px;
    text-align: left;
    border-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: lightgray;
}
