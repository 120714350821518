.tools-panel {
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    margin-right: 5px;
    padding-top:5px;
    box-sizing: border-box;
}
