.menu {
    padding: 3px 10px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
}

.menu:hover {
    border-color: #9c9c9c;
    box-sizing: content-box;
}
