#preview {
    display: flex;
}

.previewGrid {
    display: inline-flex;
    flex-direction: column;
    border: 1px solid gray;
}

.previewRow {
    display: inline-flex;
}

.previewKnot {
    width: 15px;
    height: 15px;
    border: 1px solid lightgray;
    background-size: 15px 15px;
}
