.toolbar {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 2px 5px;
    margin-left: 40px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    margin-bottom: 5px;
}

.item {
    height: 24px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.changeSizeBtnl {
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right: 0;
    border-style: solid;
    border-color: #aaaaaa;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 22px;
    cursor: pointer;
}

.changeSizeBtnr {
    border-left-width: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right: 1px;
    border-style: solid;
    border-color: #aaaaaa;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 22px;
    cursor: pointer;
}

.item-input {
    height: 22px;
    border: 1px solid #aaaaaa;
    outline: none;
    outline-offset: unset;
}

.item-input input {
    border: none;
    width: 25px;
    text-align: center;
}

.item-input input:focus {
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
.item-input input::-webkit-outer-spin-button,
.item-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.item-input input[type=number] {
    -moz-appearance: textfield;
}

.button-item {
    font-size: 0.8em;
    display: inline-flex;
    align-items: center;
    padding: 1px 10px;
}

.button-item > span {
    margin-left: 5px;
}

.item:hover {
    background-color: #eeeeee;
}

.disabled {
    color: lightgray;
    cursor: default;
    pointer-events: none;
}

.item.disabled:hover {
    background-color: inherit !important;
}
