.iconSelector {
    display: flex;
}

.icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 5px;
}

.icon.selected {
    background-color: #ffe283;
}
