#WorkArea {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: 50px;
    padding: 20px;
}

#WorkArea:focus {
    outline: none;
}

.mainContent {
    display: flex;
}
.boardPanel {
    display: inline-flex;
    align-items: flex-end;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    padding: 5px;
}
