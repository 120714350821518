.legend {
    width: 20em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: 20px;
    min-width: 20em;
}

.legendHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 5px;
}

.title {
    margin-bottom: 5px;
}

.entry {
    display: inline-flex;
    align-items: center;
    margin-bottom: 3px;
    border: 1px solid #efefef;
    border-radius: 5px;
    cursor: pointer;
}

.entrySelected {
    background-color: #ffe283;
}

.entry:hover .entryEdit {
    visibility: visible;
}

.entryIcon {
    border: 1px solid #999;
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.entryText {
    min-width: 15em;
}

.entryEdit, .entryDelete {
    visibility: hidden;
    color: #9f0000;
}

.entry:hover .entryEdit,
.entry:hover .entryDelete {
    visibility: visible;
}

.entryEdit:hover, .entryDelete:hover, .entryAdd:hover {
    color: #ee0000;
}

.entryHidden {
    visibility: hidden !important;
}

