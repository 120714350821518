.imageControls {
    display: inline-flex;
    justify-content: flex-end;
    color: darkgray;
    cursor: default;
}

.imageControls > svg {
    cursor: pointer;
}

.imageControls > svg:hover {
    color: black;
}
